.projects
  margin: 5%
  padding-bottom: 0
  margin-top: 5vw
  
.sectionTitles
  font-weight: 100
  text-transform: capitalize
  line-height: 1
  font-size: 1.5vw
  margin-bottom: 1.25vw

.workButtons
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 4vh

.workButtons h1
  margin: 0
  line-height: 0

.workButtons a
  display: flex
  justify-content: center

.projects .workButtons p
  opacity: 1
  font-size: 1vw
  line-height: initial
  margin: 0
  color: #fff

.workButtons svg
  margin-right: 1vw
  height: 1.25vw
  width: 1.25vw

.projectTitle
  margin-bottom: 0
  font-size: 1.65vw
  margin: 1ch 0 0.6ch 0
  transition: 0.15s

.projects ul
  padding: 0
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  list-style: none
  margin: 0
  gap: 3vw

.imageBack
  padding: 1rem
  background-color: #00dbde
  box-shadow: inset 0 0 10px #000
  border-radius: var(--borderRadius)

.projectImg
  position: relative
  width: 100%
  padding: 25.4% 0
  border-radius: var(--borderRadius)
  img
    border-radius: var(--borderRadius)

.projects ul li:nth-of-type(1) .imageBack
  background-color: #9ddb2b

.projects ul li:nth-of-type(2) .imageBack
  background-color: #00dbde

.projects ul li:nth-of-type(3) .imageBack
  background-color: #f05

.projects ul li:nth-of-type(4) .imageBack
  background-color: #4e6ec8

.projects ul li:nth-of-type(5) .imageBack
  background-color: #8400ff

.projects ul li:nth-of-type(6) .imageBack
  background-color: rgb(255, 166, 0)

.projects a
  text-decoration: none
  padding-bottom: 0.5vw
  transition: 0.1s

.summary
  display: flex
  align-items: center
  border-bottom: 1px solid #909090

.projects a p
  color: #8f8f8f
  transition: 0.15s
  font-size: 16px
  line-height: 1.7
  overflow: hidden
  margin-top: 0
  text-overflow: ellipsis
  display: -webkit-inline-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical

.projects a:hover p
  color: white

.projects svg
  min-width: 5ch
  width: 5ch
  max-width: 5ch
  min-height: 5ch
  height: 5ch
  max-height: 5ch
  margin-left: 1ch
  will-change: transform

.projects a:hover svg
  animation: arrowMove 1s infinite

@keyframes arrowMove
  0%
    transform: translateX(0%)
  50%
    transform: translateX(15%)
  100%
    transform: translateX(0%)

.projects ul li:nth-of-type(1) a:hover 
  .summary
    color: #9ddb2b
    border-color: #9ddb2b
  .projectTitle
    color: #9ddb2b

.projects ul li:nth-of-type(2) a:hover 
  .summary
    color: #00dbde
    border-color: #00dbde
  .projectTitle
    color: #00dbde

.projects ul li:nth-of-type(3) a:hover 
  .summary
    color: #f05
    border-color: #f05
  .projectTitle
    color: #f05

.projects ul li:nth-of-type(4) a:hover 
  .summary
    color: #4e6ec8
    border-color: #4e6ec8
  .projectTitle
    color: #4e6ec8

.projects ul li:nth-of-type(5) a:hover 
  .summary
    color: #8400ff
    border-color: #8400ff
  .projectTitle
    color: #8400ff

.projects ul li:nth-of-type(6) a:hover 
  .summary
    color: rgb(255, 166, 0)
    border-color: rgb(255, 166, 0)
  .projectTitle
    color: rgb(255, 166, 0)

@media only screen and (max-width: 1200px)
  .projects
    padding: 0 4vw
    margin: 0
    margin-top: 10vw
    ul
      grid-template-columns: 1fr 1fr
  .imageBack
    padding: 1.25ch
  .sectionTitles
    font-size: 2vw
    margin-bottom: 2vw
  .projectTitle
    font-size: 3ch
  .projects a p
    font-size: 2ch

@media only screen and (max-width: 875px)
  .sectionTitles
    font-size: 3vw
    margin-bottom: 3vw

@media only screen and (max-width: 600px)
  .projects
    margin-top: -35vw
    ul
      width: 92vw
      grid-template-columns: 1fr
      gap: 10vw
  .sectionTitles
    font-size: 4.5vw
    margin-bottom: 4.5vw
  .projectTitle
    font-size: 5.5vw
    padding: 0
    margin: 3.5vw 0 1.5vw 0
  .projects a p
    font-size: 3.5vw
    margin: 0
    padding: 0
    margin-bottom: 3.5vw
  .projects svg
    min-width: 9vw
    width: 9vw
    max-width: 9vw
    min-height: 9vw
    height: 9vw
    max-height: 9vw
    margin-left: 1vw