.gradientText
  display: inline-block
  position: relative
  background: linear-gradient(270deg, #00dbde 0, #8400ff 50%, #f05 100%)
  background-size: 200%
  -webkit-animation: wipe 5s ease-in-out infinite 
  -moz-animation: wipe 5s ease-in-out infinite 
  -o-animation: wipe 5s ease-in-out infinite 
  animation: wipe 5s ease-in-out infinite 
  will-change: background-position
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  font-weight: 400

@-webkit-keyframes wipe
  0%
    background-position: 10% 0
  50%
    background-position: 100% 0
  100%
    background-position: 10% 0
  
@-moz-keyframes wipe
  0%
    background-position: 10% 0
  50%
    background-position: 100% 0
  100%
    background-position: 10% 0
  
@-o-keyframes wipe
  0%
    background-position: 10% 0
  50%
    background-position: 100% 0
  100%
    background-position: 10% 0

@keyframes wipe
  0%
    background-position: 10% 0
  50%
    background-position: 100% 0
  100%
    background-position: 10% 0

.pImgs
  filter: contrast(130%)
  pointer-events: none

.hero
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100vw
  box-sizing: border-box
  padding: 5vw
  padding-bottom: 0
  padding-top: 7.5vw
  position: relative

.emoji
  font-size: 2.25vw
  display: inline-block
  filter: saturate(120%) contrast(120%)
  user-select: none

.profileImg
  position: relative
  width: 22vw
  height: 22vw
  transform: translateY(7.75vw)

.hero h1
  display: flex
  flex-flow: column

.headingSentence
  font-weight: 700
  line-height: 1.0625
  letter-spacing: -0.0045em
  margin: 0
  font-size: 3.5vw
  text-transform: capitalize
  span
    line-height: 1.25

.introSentence
  font-weight: 100
  font-size: 1.5vw
  margin-bottom: 2ch

.hero h2
  margin-top: 1.5vw
  display: flex
  flex-flow: column

.heroArticle
  width: 55%

.headingSection
  display: flex
  align-items: center
  font-size: 1.25vw
  position: relative
  padding-left: 0
  margin-top: 1ch
  font-weight: 100
  margin-right: 1ch
  span
    padding-left: 1ch
    z-index: 2
    color: #8f8f8f

.headingIntro
  padding-left: 0.4ch
  font-size: 1.5vw
  margin: 0
  margin: 1ch 0
  span
    padding-left: 0
    color: #dfdfdf

.headingTraits
  display: flex
  flex-flow: wrap
  width: 90%

.headingSection:nth-of-type(2)
  .emoji
    filter: contrast(100%) saturate(120%)
    transform: scale(0.9)

.headingSection:nth-of-type(3)
  .emoji
    filter: contrast(80%) saturate(120%)

.headingSection:nth-of-type(4)
  .emoji
    filter: contrast(100%) saturate(120%)

.headingSection:nth-of-type(5)
  .emoji
    transform: scale(0.85)

@media only screen and (max-width: 1200px)
  .hero
      padding: 0 4vw
      justify-content: flex-start
      align-items: flex-start

  .emoji
      font-size: 4ch

  .hero h2
      margin: 0
  .heroArticle
      width: 80vw
  .introSentence
      font-size: 1.5ch
      margin-bottom: 1.5ch
  .headingSentence
      font-size: 5vw
      width: 95%
  .headingSection
      font-size: 1.25ch
      font-weight: 400
      margin-right: 2.75vw
  .headingIntro
    font-size: 1.5ch
    width: 90vw
    font-weight: 100
    span
        padding-left: 0
  .profileImg
      width: 27vw
      min-width: 27vw
      height: 27vw
      align-self: flex-end
      transform: translate(-13vw, 14vw)

@media only screen and (max-width: 875px)
  .headingSentence
      font-size: 6vw
      width: 95%
  .profileImg
      width: 35vw
      min-width: 35vw
      height: 35vw
      transform: translate(-20vw, 16vw)

@media only screen and (max-width: 600px)
  .hero
    padding: 0 3vw
    flex-flow: column
  .headingTraits
    flex-flow: column
  .emoji
      font-size: 6vw
  .heroArticle
      width: 94vw
  .introSentence
      font-size: 4vw
  .headingSentence
      font-size: 9.5vw
      width: 100%
  .headingSection
      font-size: 3.5vw
  .headingIntro
    font-size: 3.75vw
    width: 90vw
  .profileImg
    width: 60vw
    height: 60vw
    transform: translate(6vw, -26vw)