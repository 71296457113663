.aboutImg
  width: 30vw
  height: 30vw
  position: absolute
  left: 10vw
  top: 0%

.stars
  margin: 0 5vw 7.5vw 5vw
  display: flex
  justify-content: space-between
  align-items: flex-start
  position: relative

.rating
  display: flex
  justify-content: center
  flex-direction: column
  position: relative

.five
  display: flex
  align-items: center
  font-size: 2ch
  letter-spacing: 0.5ch

.ratingNum
  font-size: 8ch
  margin: 0
  font-weight: 700
  span
    font-size: 0.6ch
    letter-spacing: 0.35ch
    font-weight: 100

.emoji
  display: inline-block
  font-size: 5ch
  user-select: none

.stars .tagLine
  font-size: 2ch
  font-weight: 100
  line-height: 2.5
  display: flex
  align-items: center

.aspects
  padding: 0
  list-style: none
  width: 60%
  article
    display: flex
    align-items: center
    width: 60%
  p
    font-size: 2ch
    margin-left: 2ch
    line-height: 1
  li
    border-bottom: 1px solid #474747
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 1.5ch
    padding-bottom: 1.5ch
  li:last-child
    margin-bottom: 0

@media only screen and (max-width: 1200px)
  .five
    font-size: 2.15ch
    letter-spacing: 0.15ch
  .emoji
    font-size: 5ch
  .ratingNum
    font-size: 8ch
  .stars
    flex-flow: column
    margin: 27.5vw 5vw 10vw 5vw
    .tagLine
      font-size: 2.25ch
      margin-top: 0.5ch
  .aboutImg
    width: 45vw
    height: 45vw
    top: auto
    bottom: -20%
    left: 14ch
  .aspects
    margin-top: 3ch
    width: 90vw
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 5vw
    row-gap: 2.5vw
    p
      font-size: 2ch
      line-height: 1.7
      margin-left: 1.5ch
    li
      padding: 2ch  0

@media only screen and (max-width: 760px)
  .aspects
    display: flex
    flex-flow: column
  .aboutImg
    width: 60vw
    height: 60vw
    bottom: -25%
    left: 16ch

@media only screen and (max-width: 600px)
  .aboutImg
    min-width: 90vw
    width: 90vw
    height: 90vw
    bottom: -5%
    left: auto
    right: -20vw
    justify-self: flex-end
    align-self: flex-end
  .ratingNum
    font-size: 13vw
  .five
    font-size: 4vw
    letter-spacing: 1vw
  .emoji
    font-size: 8vw
  .aspects
    margin-top: 3vw
    p
      font-size: 3.5vw
    li
      padding: 3vw 0
      margin-bottom: 3vw
  .stars
    align-items: unset
    margin: 60vw 4vw 20vw 4vw
    width: 94vw
    .tagLine
      font-size: 4vw