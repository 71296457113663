.header
  width: 100%
  position: fixed
  box-sizing: border-box
  z-index: 3
  background-color: #000
  padding: 2vw 5vw
  top: 0
  display: flex
  align-items: center
  justify-content: space-between

.homeBtn
  width: 48px
  height: 48px
  position: relative

.header p
  margin: 0
  font-size: 0.75vw

.headerLinks
  display: flex
  align-items: center

.links
  display: flex
  list-style: none
  padding: 0
  margin: 0
  position: relative

.link
  cursor: pointer
  font-size: 2ch
  margin-left: 14ch

.hContactBtn
  margin-left: 20%

.link:last-child p
  margin-bottom: 0

.header .emailBar a
  text-decoration: none
  transition: 0.1s
  border-bottom: 0.1vw solid #fff
  position: relative
  padding-bottom: 1.15ch
  padding-left: 1ch
  padding-right: 3ch

.header .emailBar a span
  font-weight: 700
  font-size: 2.5ch
  margin: 0
  color: #fff
  box-sizing: border-box
  font-weight: 100

.header .emailBar a:hover
  border-color: #00dbde

.header .emailBar a:hover span
  color: #00dbde

.emailBar button
  color: #fff
  background-color: transparent
  border: 1px solid #fff
  padding: 1.5ch 2ch
  box-sizing: border-box
  margin: 0
  transition: 0.1s
  cursor: pointer
  font-size: 2ch
  width: 14ch
  border-radius: var(--borderRadius)
  margin-left: -1.5ch
  z-index: 1

.emailBar button:hover
  color: #8400ff
  border-color: #8400ff

.header .copied
  border-color: #f05 !important
  color: #f05 !important

.emailBar
  display: flex
  justify-content: center
  align-items: flex-end

.underline
  position: absolute
  bottom: min(-1.2vw, -20px)
  z-index: 99
  transition: left 0.25s, width 0.25s
  border: none
  outline: 0
  background-color: #fff
  height: max(0.1vw, 2px)
  width: 0
  left: 0

@media only screen and (max-width: 1200px)
    
  .links
    margin: 0
  
  .link
    display: none
    line-height: 0
  
  .homeSec
    display: block
    margin: 0
  
  .underline
    display: none

  .header
    width: 100vw
    position: static
    display: flex
    align-items: center
    padding: 2vw 4vw

@media only screen and (max-width: 600px)
  .homeBtn
    width: 10vw
    height: 10vw
  .header
    padding: 4vw
    button
      font-size: 3vw
      width: 24vw
      padding: 3vw 0
      border-width: 1px
    a
      line-height: 0
    img
      margin: 0
    .emailBar
      margin: 0
      a
        padding-bottom: 1.5vw
        padding-right: 5vw
        padding-left: 1vw
        border-width: 1px
        span
          font-size: 4vw