.footer
  border-top: 1px solid #151515
  box-sizing: border-box

.footerContent
  padding: 5vw
  display: flex
  justify-content: space-between

.footerImg
  position: relative
  width: 20vw
  height: 20vw
  transform: scaleX(-1) rotate(10deg)
  margin-top: -3vw

.content
  width: 65vw

.footer img
  filter: contrast(120%)
 
.tagLine
  font-size: 1.75vw
  margin: 0
  font-weight: 100
  text-transform: capitalize
  margin: 0
  white-space: nowrap

.ctaText
  font-size: 5vw
  margin: 0
  font-weight: 700
  margin-bottom: 0.25ch

.footer a
  text-decoration: none
  transition: 0.1s
  border-bottom: 0.15ch solid #a9a9a9
  position: relative
  padding: 0 3.5ch 2.15ch 1ch
  transition: 0.15s

.footer a span
  font-weight: 700
  font-size: 2.5vw
  margin: 0
  color: #a9a9a9
  box-sizing: border-box
  font-weight: 100
  line-height: 0
  transition: 0.15s

.footer a:hover span
  color: #00dbde

.footer a:hover
  border-color: #00dbde

.footerText
  font-size: 2ch
  font-weight: 100
  color: #ffffff
  background-color: #202020
  opacity: 0.5
  margin: 0
  padding: 1ch
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.footer button
  color: #a9a9a9
  background-color: transparent
  border: 0.15ch solid #a9a9a9
  padding: 1.75ch 0
  box-sizing: border-box
  margin: 0
  transition: 0.1s
  cursor: pointer
  font-size: 2.5ch
  width: 14ch
  min-width: 14ch
  border-radius: var(--borderRadius)
  margin-left: -1ch
  z-index: 1

.footer button:hover
  border-color: #8400ff
  color: #8400ff

.footer .copied
  border-color: #f05 !important
  color: #f05 !important

.emailBar
  display: flex
  justify-content: stretch
  align-items: flex-end

.links
  list-style: none
  text-decoration: none
  display: flex
  flex-flow: wrap
  gap: 3vw
  margin-top: 3vw
  li
    width: fit-content
  li:nth-of-type(1) img
    transform: scale(0.95)
  li:nth-of-type(1) a:hover 
    p
      border-color: #f05 !important
      color: white
    .footerLink
      color: #f05
  li:nth-of-type(2) a:hover 
    p
      border-color: #8400ff !important
      color: white
    .footerLink
      color: #8400ff
  li:nth-of-type(3) a:hover 
    p
      border-color: #00dbde !important
      color: white
    .footerLink
      color: #00dbde
  a
    all: unset
    cursor: pointer
    display: flex
    align-items: center
    transition: 0.1s
    padding: 0
    p
      line-height: 1.5
      border-bottom: 1px solid transparent
      margin: 0
      font-size: 2ch
      color: #808080
      padding: 0.25ch 0 1ch 0
      transition: 0.15s
    .icon
      margin: 0
      transform: translateY(-0.5ch)
      margin-right: 0.5ch
      margin-left: -0.6ch
    .emoji
      position: relative
      width: 2.5ch
      height: 2.5ch
      display: inline-block
    .footerLink
      font-size: 2.5ch
      line-height: 1.5
      transition: 0.15s

@media only screen and (max-width: 1200px)

  .footerImg
    width: 27vw
    height: 27vw

  .content
    width: 58vw

  .tagLine
    font-size: 2.5ch
  
  .ctaText
    font-size: 6.5ch
  
  .footer 
    a
      span
        font-size: 3ch
    button
      font-size: 2ch

  .emailBar
    a
      padding: 0 3ch 1.75ch 1ch

  .links
    gap: 0
    li
      margin-right: 3vw
      margin-bottom: 3vw
    li:last-child
      margin: 0
    a
      .emoji
        width: 3.5ch
        height: 3.5ch
      .icon
        margin-left: -0.8ch

@media only screen and (max-width: 840px)
  .footerContent
    flex-flow: column
    padding-top: 0
    justify-content: flex-end
    align-items: flex-end

  .footerImg
    position: relative
    width: 40vw
    height: 40vw
    transform: scaleX(-1) rotate(15deg) translate(50vw, -13vw)

  .links
    margin-top: 3.5ch
    li
      margin-right: 4vw
      margin-bottom: 4vw

@media only screen and (max-width: 715px)

  .footerContent
    flex-flow: column
    width: 100vw

  .tagLine
    width: 100%
  
  .ctaText
    width: 100%

  .content
    display: flex
    flex-flow: column
    width: 100%

  .links
    flex-flow: column
    width: 100%
    li
      width: 100%
      background-color: #151515
      background: linear-gradient(45deg, #101010 0, #151515 100%)
      border-radius: var(--borderRadius)
      padding: 1.5vw
      box-sizing: border-box
      box-shadow: 0 0 10px inset rgba(0,0,0,0.5)
    a
      background-color: #15151b
      background: linear-gradient(45deg, #131317 0, #15151b 100%)
      width: 100%
      padding: 1.5vw 2.25vw
      box-sizing: border-box
      border-radius: var(--borderRadius)
      box-shadow: 0 5px 10px rgba(0,0,0,0.5)
      article
        width: 100%
      p
        width: 100%

  .emailBar
    width: 100%
    a
      width: 100%
        
  .footerText
    width: 100vw

@media only screen and (max-width: 600px)
  .footerContent
    padding-top: 0
    padding: 0 4vw 6vw 4vw

  .footerImg
    position: relative
    width: 50vw
    height: 50vw
    transform: scaleX(-1) rotate(15deg) translate(44vw, -12vw)
    margin-top: -4vw

  .links
    margin-top: 8vw
    flex-flow: column
    a
      .emoji
        width: 9vw
        height: 9vw
      .footerLink
        font-size: 4vw
      p
        font-size: 3.5vw
      .icon
        margin-left: -1.5vw
        margin-right: 2.5vw

  .tagLine
    font-size: 4vw
  
  .ctaText
    font-size: 10vw
    margin: 2vw 0 1vw 0
  
  .footer 
    a
      span
        font-size: 5.5vw
    button
      font-size: 3.5vw

  .emailBar
    a
      padding: 0 0 3.25vw 1vw
        
  .footerText
    font-size: 3.5vw