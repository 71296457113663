$skillGrid: #050505, #101010, #151515
$learningGrid: #15151b, #131317, #21212a

.categorySingle
	background-color: #454558
	position: absolute
	bottom: 0
	width: 90%
	height: 3ch
	padding: 0 1.5ch
	border-radius:  10px
	line-height: 3ch
	white-space: nowrap
	font-size: 1.5ch

.category
	background-color: #454558
	position: absolute
	bottom: 0
	width: 100%
	height: 3ch
	padding: 0 1.5ch
	border-radius:  10px 0 0 10px
	line-height: 3ch
	white-space: nowrap
	font-size: 1.5ch
	margin-left: 10%

.categoryMiddle
	background-color: #454558
	position: absolute
	bottom: 0
	width: 100%
	height: 3ch
	line-height: 3ch
	font-size: 1.5ch

.categoryEnd
	background-color: #454558
	position: absolute
	bottom: 0
	width: 100%
	height: 3ch
	border-radius:  0 10px 10px 0
	line-height: 3ch
	font-size: 1.5ch
	margin-right: 10%

.sectionTitles
	font-weight: 100
	text-transform: capitalize
	line-height: 1
	font-size: 1.25vw
	margin-bottom: 1.25vw

.skillImg
	position: absolute
	transform: translateY(-1ch)
	width: 100%
	min-width: 100%
	padding: 50% 0

.learningTag
	color: #999999
	font-weight: 400
	position: absolute
	bottom: 5ch
	font-size: 1.25ch
	text-transform: uppercase
	pointer-events: none

.skills 
	margin: 7.5vw 5vw
	padding-bottom: 0
	ul
		display: grid
		padding: 0
		list-style: none
		width: 100%
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
	li 
		box-sizing: border-box
		width: 100%
		height: 100%
		padding: 50% 0
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		transition: 0.1s
		position: relative
		overflow: hidden
		article
			display: flex
			flex-flow: column
			justify-content: center
			align-items: center
			position: absolute
			width: 100%
			top: 50%
			left: 50%
			transform: translateY(-50%) translateX(-50%)
	li:first-child 
		background-color: transparent
		justify-content: flex-start
		align-items: flex-start
		background: 0 0
		padding: 0
		margin: 0
		img
			filter: contrast(120%)
	p
		margin: 0
		margin-top: 0.5vw
		font-size: 1.5ch
		pointer-events: none
		text-align: center
		opacity: 0.5
	h3
		margin: 0
		font-size: 2.5ch
		font-weight: 400
		pointer-events: none
		text-align: center
		white-space: nowrap
	h1
		margin: 0
	li:nth-of-type(2n)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)
	.skillContent
		border-radius: var(--borderRadius)
		justify-content: center
		align-items: center
		display: flex
		background-color: #282b4c
		background: linear-gradient(45deg, nth($learningGrid, 1) 0, nth($learningGrid, 2) 100%)
		height: 90%
		width: 90%
		position: absolute
		box-sizing: border-box
	.skillBlock:nth-of-type(2n) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
	.learningBlock .skillContent
		background-color: #101010
		background: linear-gradient(45deg, nth($skillGrid, 1) 0, nth($skillGrid, 2) 100%)
	.learningBlock:nth-of-type(2n) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)

@media only screen and (max-width: 1200px)
	.skillImg
		position: absolute
		align-self: center
		justify-self: center
		margin-top: 3vw
	.sectionTitles
		font-size: 2vw
		margin-bottom: 2vw
	.skills
		margin: 10vw 4vw 0 4vw

	.skills .skillBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 1) 0, nth($learningGrid, 2) 100%)

	.skills .skillBlock:nth-of-type(10n-8) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(10n-6) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(10n-4) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(10n-2) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(10n) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 1) 0, nth($skillGrid, 2) 100%)

	.skills .learningBlock:nth-of-type(10n-8) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(10n-6) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(10n-4) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(10n-2) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(10n) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

@media only screen and (max-width: 875px)
	.sectionTitles
		font-size: 3vw
		margin-bottom: 3vw

	.skills p
		margin-top: 1vw

	.skills .skillBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 1) 0, nth($learningGrid, 2) 100%)
		box-shadow: none

	.skills .skillBlock:nth-of-type(8n-6) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(8n-4) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(8n-1) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(8n-3) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 1) 0, nth($skillGrid, 2) 100%)
		box-shadow: none

	.skills .learningBlock:nth-of-type(8n-6) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(8n-4) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(8n-1) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(8n-3) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

@media only screen and (max-width: 775px)

	.skills .skillBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 1) 0, nth($learningGrid, 2) 100%)
		box-shadow: none

	.skills .skillBlock:nth-of-type(6n-6) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(6n-4) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(6n-2) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 1) 0, nth($skillGrid, 2) 100%)
		box-shadow: none

	.skills .learningBlock:nth-of-type(6n-6) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(6n-4) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(6n-2) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

@media only screen and (max-width: 600px)
	.skills
		margin: 30vw 3vw 0 3vw

	.sectionTitles
		font-size: 4.5vw
		margin-bottom: 4.5vw

	.skills ul
		width: 94vw
		grid-template-columns: 1fr 1fr

	.skills h3
		font-size: 4.5vw

	.skills p
		font-size: 3vw
		margin-top: 1.75vw

	.learningTag
		bottom: 7vw
		font-size: 3vw

	.skills .skillBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 1) 0, nth($learningGrid, 2) 100%)
		box-shadow: none

	.skills .skillBlock:nth-of-type(4n-1) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .skillBlock:nth-of-type(4n-2) .skillContent
		background: linear-gradient(45deg, nth($learningGrid, 2) 0, nth($learningGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(n+2) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 1) 0, nth($skillGrid, 2) 100%)
		box-shadow: none

	.skills .learningBlock:nth-of-type(4n-1) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)

	.skills .learningBlock:nth-of-type(4n-2) .skillContent
		background: linear-gradient(45deg, nth($skillGrid, 2) 0, nth($skillGrid, 3) 100%)
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.75)